import Vue from "vue/dist/vue.min";
import Vuex from "vuex";

Vue.use(Vuex);

import store from "../../../store/index";

const PortfolioTeaserGroup = () => import(/* webpackChunkName: "PortfolioTeaserGroup" */ "./PortfolioTeaserGroup.vue");

function init() {
    document.querySelectorAll('[data-vue="portfolio-teaser-group"]').forEach((el) => {
        return new Vue({
            el,
            store,
            components: { PortfolioTeaserGroup },
        });
    });
}

if (document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        init();
    });
}
